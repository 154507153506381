<template>
  <div>
    <div class="mainwidth">
      <div class="news-top">
        <div class="news-nav ">
          <ul>
            <li :class="{ liactive: true }">{{ name }}</li>
          </ul>
        </div>
      </div>
      <div
        class="news-con"
        v-if="
          $route.query.id == 1 ||
            $route.query.id == 4 ||
            $route.query.id == 5 ||
            $route.query.id == 7 ||
            $route.query.id == 8
        "
      >
        <ul>
          <li v-for="(sItem, si) in sList" :key="si">
            <div class="item-right-top">
              <div class="title" style="font-weight: bold">{{ sItem.t }}</div>
              <div class="item-right-bot">
                <p>{{ sItem.phone }}</p>
                <p
                  style="line-height: 33px;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 3;-webkit-box-orient: vertical;"
                >
                  {{ sItem.info }}
                </p>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="news-con" v-if="$route.query.id == 2">
        <p style="font-weight: bold">哈尔滨产融信息服务有限公司</p>
        <div>
          <img
            style="width:100px"
            src="../assets/images/business/1.png"
            alt=""
          />
        </div>
        <p style="font-weight: bold">
          哈尔滨产融信息服务有限公司由哈尔滨开发区中小企业融资担保有限公司和黑龙江省范式智能技术有限公司共同投资，于2019年正式成立。公司面向区域政府、金融机构和中小微企业负责“龙哈智能产融综合服务平台”（以下简称平台）的整体业务运营。平台在运行机制、业务流程、保障体系、金融产品等方面的创新实践，为解决区域民营企业及中小微企业融资难、融资贵、融资慢等方面做出了贡献，平台提供的“社会信用+科技风控+普惠金融”服务，为实现产融合作下的政、企、金融机构多方共赢提供了方案。
        </p>
        <p>股东单位介绍:</p>
        <div>
          <img
            style="width:100px"
            src="../assets/images/business/3.jpg"
            alt=""
          />
        </div>
        <p>
          <span class="spanBlod">哈尔滨开发区中小企业融资担保有限公司</span> ，是云谷投资控股（集团）有限公司旗下的国有控股企业。哈尔滨开发区中小企业融资担保有限公司成立于2003年，公司经营范围包括为开发区中小企业办理信用担保及信用担保金的筹措业务。
        </p>
        <div>
          <img
            style="width:100px"
            src="../assets/images/business/4.png"
            alt=""
          />
        </div>
        <p>
          <span class="spanBlod">黑龙江省范式智能技术有限公司</span> ，是一家拥有独立运营和产品研发团队的高新技术企业，公司基于互联网、云计算、大数据、人工智能、区块链等先进信息技术，以AI赋能产业，创新整合优势数据、技术、市场资源，搭建金融、信用、经济运行监测等智能应用平台，助推区域、行业、企业转型升级和增强核心竞争力。
        </p>
      </div>
      <div class="news-con" v-if="$route.query.id == 3">
        <div>
          <img
            style="width:100px"
            src="../assets/images/business/2.png"
            alt=""
          />
        </div>
        <p style="margin-top:20px">
          <span class="spanBlod">知观知策产业经济研究（黑龙江）有限公司</span> ，简称“知观智库”，是黑龙江首家针对本土经济和金融领域进行专业数据治理和产业研究的咨询机构。
          <br />
          知观智库，以“极致缜密思维为
          客户创造价值”为理念，以中国信通院产融数据为基础，从专业咨询角度系统洞察龙江产业现状，梳理符合当地经济发展的产业链体系，助力政府在经济振兴、招商引资、产业金融等方面进行科学决策，<span class="spanBlod">帮助大型企业集团在龙江产业落地的企业尽调和上下游营商环境调研等</span> ，为金融和类金融机构提供产业经济报告等第三方咨询服务。
          <br />
          知观智库致力于为政府和企业提供区域产业研究、产业链分析报告、重点企业培育方案和产业落地辅导四大核心业务版块的咨询服务。自成立以来，已为黑龙江省多市委办局和区域政府提供第三方咨询服务，已完成《航天产业研究报告》
          、《哈尔滨数字经济产业研究报告》、《五常产业振兴行动计划方案》
          、《黑龙江生物医药产业研究报告》
          、《黑龙江畜牧产业研究报告》、《哈尔滨工业倍增方案》等。
        </p>
        <div
          style="display:flex;align-items:center;justify-content:space-around"
        >
          <img
            style="width:100px"
            src="../assets/images/business/5.png"
            alt=""
          />
          <img
            style="width:100px"
            src="../assets/images/business/6.png"
            alt=""
          />
          <img
            style="width:100px"
            src="../assets/images/business/7.png"
            alt=""
          />
          <img
            style="width:100px"
            src="../assets/images/business/8.png"
            alt=""
          />
        </div>
      </div>
      <div class="news-con" v-if="$route.query.id == 6">
        <div style="text-align:center">
          <img
            style="width:400px"
            src="../assets/images/business/lvsuo.jpg"
            alt=""
          />
          <h1 style="font-weight: bold">铭昊（哈尔滨）律师事务所简介</h1>
        </div>
        <p style="text-indent:24px">
          黑龙江铭昊律师事务所，成立于2004年，是一家实行公司化运营的综合性普通合伙制律师事务所。现设立有黑龙江铭昊（哈尔滨）律师事务所和黑龙江铭昊（齐齐哈尔）律师事务所，以及北京铭昊法商管理顾问公司。曾于2013年、2018年两度获评为“黑龙江省优秀律师事务所”。2012年获评为“黑龙江省律师事务所信用五星级单位”。2014年获评为黑龙江省“六五”普法先进集体。“政治引领，党建先行”，铭昊律所党支部成立于2016年，律所党支部班子成员与律所管委会实行“双向进入、交叉任职”，将优秀律师培养成党员，把党员培养成优秀律师。2018年，黑龙江铭昊律师事务所党支部被评定为“黑龙江省律师行业党建工作示范基地”、“律师行业先进党支部”。2020年律所被评定为黑龙江省“七五”普法先进集体。2021年律所入选为全国中小企业合作发展促进中心“法律服务专家顾问团成员单位”。
        </p>
        <div style="text-align:center">
          <img src="../assets/images/business/lvsuo-1.jpg" alt="" />
        </div>
        <div style="text-align:center;margin-top: 20px;">
          <img src="../assets/images/business/lvsu0-2.jpg" alt="" />
        </div>
        <p class="indetP" style="margin-top: 20px;">
          2013年黑龙江铭昊（哈尔滨）律师事务所成立。铭昊作为黑龙江省品牌律所，在多方面存在显著优势。
        </p>
        <p class="indetP">
          <span class="spanBlod">平台资源优势。</span> 铭昊作为全球法律数字平台“数字瀛和”的黑龙江数据中心，国内法律服务行业数字化发展探索者之一，创设数字化法律服务系统，为客户提供可视化法律服务，以互联网思维打造数字化律所，以数字化法律服务构建高效透明的律企通道。铭昊律师事务所为客户提供优质法律服务的同时，对接全国平台乃至全球高端律师事务所平台，能够顾为客户提供最高水平的法律服务，同时联合司法系统及众多学术实务界专家亦可为客户提供各类有效资源。并与黑龙江广播电视台，龙广946说法，龙广998以及报纸等都保持着良好合作关系。铭昊热心公益，举办多种公益活动，回馈社会。
        </p>
        <p class="indetP">
          <span class="spanBlod">服务创新优势。</span> 专项研发几十个领域的法律服务产品，可为客户进行定制化服务，平台化管理模式、智能化风控体系、提供专业分工服务。铭昊规范的客户服务流程，专业律师的贴心服务，最大程度的保障客户的合法权益及个人隐私。铭昊律师事务所的众多资深律师，同时拥有注册会计师、税务师、企业合规师等资格，为客户提供全方位的服务方案。
        </p>
        <p class="indetP">
          <span class="spanBlod">品牌诚信优势。</span> 铭昊先后成立了铭昊（齐齐哈尔）律师事务所、铭昊（哈尔滨）律师事务所，先后获得2次获得“黑龙江省优秀律师事务所”、“黑龙江省律师事务所诚信五星级单位”等众多奖项，铭昊用品牌与实力赢得了社会信任和口碑的同时，也得到了政府与企业的认可。
        </p>
        <p class="indetP">
          <span class="spanBlod">专业团队优势。</span> 铭昊以律师团队的形式为企业提供法律服务有别于一个律师单打独斗的服务方式，目前拥有14个专业化律师团队。企业的大部分案件均由律所律师集体讨论，对重大案件，由专家把关，集思广益、为客户探索最有利的方案。既有专业分工，又互相协调工作。团队成员皆由经验丰富的专业律师组成，能最大限度地为客户提供优质化服务。
        </p>
        <p class="indetP">
         <span class="spanBlod">思想文化优势。</span> 铭昊律所始终坚持“政治引领，党建先行”，铭昊律所党支部，积极发挥着战斗堡垒作用和先锋模范作用，牢固树立“党建就是第一生产力，党建强所建，党务强业务，党风强作风”的发展理念，有效实现了党建工作与律所发展共建共赢的良好局面。铭昊注重军民融合，现有退役军人19名，将军人优秀品质融入工作中，提升律所工作能力与工作效率。
        </p>
        <p class="indetP">
          铭昊律所秉承 <span class="spanBlod">“国际视野、黑土情怀、扎根龙江、服务全国”</span> 的理念，为客户提供更为全面的法律服务。以数字化提升法律服务效率为使命，期盼打造一家具有全省影响力的法律机构。树立 <span class="spanBlod">创新、诚信、开放、共享</span> 的价值观，<span class="spanBlod">“让党放心、让客户满意、让律师幸福”</span> 是全体铭昊人共同的追求。追梦路上，铭昊人信念坚定，秉持初心，砥砺前行，为创更好的律所而不懈奋斗！
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sList: [],
      name: "",
    };
  },
  methods: {},
  created() {
    if (this.$route.query.id == 1) {
      this.name = "商务代理";
      this.sList = [
        {
          t: "黑龙江省吾尚智鼎文化发展有限公司",
          phone: "联系电话：0451-81023000/18245108077",
          info:
            "公司整体运营涉及品牌营销、书籍出版、文创研发、视频制作、空间设计、活动推广、新媒体运营七个领域，为客户提供文化创意事务的一站式解决方案。文化力推动新经济。 黑龙江省吾尚智鼎文化发展有限公司，成立于2007年，国家二级广告企业、黑龙江省一级广告公司、省级“守合同重信用”企业、系黑龙江知名文化创意机构之一，获得国家级省级各类荣誉奖项60余项，服务客户百余家。 目前企业拥有两大文化创意IP。 其一是平台：建设运营“互联网+摄影+文旅”综合性交互网络媒体“光影视界网。网站立足黑土，服务龙江，拥有“两微一站”宣传阵地，形成了今日头条、知乎、百家号、搜狐、网易、腾讯等13家网络平台组成的融媒体矩阵，发布影像、文旅内容10000余条，举办各类赛事活动200余次，已经成为宣传我省旅游资源、促进文旅融合发展的知名品牌。 其二是产品，创办龙江特色文旅文创商品品牌“龙江游礼”。目前已经开发了四个系列百余种文化创意品类，拥有一个文创产品微店，成功注册文创商标12枚，服务了10余家企事业单位高端定制文创产品开发项目，获得了20余项文创产品赛事类奖项，参加了10余次全国各类文化产业展会博览会，拥有了一定的品牌知名度和美誉度。",
        },
        {
          t: "婉菁集群产业创业中心",
          info:
            "商务秘书服务；企业孵化器服务；商标代理服务；版权代理服务；财务咨询服务；档案托管服务；企业证件代办服务；可行性研究报告攥写服务；计算机图文设计；网页设计；设计、制作、代理、发布广告业务；社会经济咨询服务；品牌策划创意服务；市场营销策划；企业形象设计；企业管理咨询服务；教育咨询服务；会议及展览展示服务；法律咨询服务等业务。",
        },
      ];
    }
    if (this.$route.query.id == 2) {
      this.name = "信息服务";
      // this.sList=[{
      //   t:'哈尔滨产融信息服务有限公司',
      //   info:'哈尔滨产融信息服务有限公司由哈尔滨开发区中小企业融资担保有限公司和黑龙江省范式智能技术有限公司共同投资，于2019年正式成立。公司面向区域政府、金融机构和中小微企业负责“龙哈智能产融综合服务平台”（以下简称平台）的整体业务运营。平台在运行机制、业务流程、保障体系、金融产品等方面的创新实践，为解决区域民营企业及中小微企业融资难、融资贵、融资慢等方面做出了贡献，平台提供的“社会信用+科技风控+普惠金融”服务，为实现产融合作下的政、企、金融机构多方共赢提供了方案。'
      // },{
      //   t:'哈尔滨开发区中小企业融资担保有限公司',
      //   info:'哈尔滨开发区中小企业融资担保有限公司，是云谷投资控股（集团）有限公司旗下的国有控股企业。哈尔滨开发区中小企业融资担保有限公司成立于2003年，公司经营范围包括为开发区中小企业办理信用担保及信用担保金的筹措业务。'
      // },{
      //   t:'黑龙江省范式智能技术有限公司',
      //   info:'黑龙江省范式智能技术有限公司，是一家拥有独立运营和产品研发团队的高新技术企业，公司基于互联网、云计算、大数据、人工智能、区块链等先进信息技术，以AI赋能产业，创新整合优势数据、技术、市场资源，搭建金融、信用、经济运行监测等智能应用平台，助推区域、行业、企业转型升级和增强核心竞争力。'
      // }]
    } else if (this.$route.query.id == 3) {
      this.name = "战略策划";
      // this.sList=[{
      //   t:'知观知策产业经济研究（黑龙江）有限公司',
      //   info:'知观知策产业经济研究（黑龙江）有限公司，简称“知观智库”，是黑龙江首家针对本土经济和金融领域进行专业数据治理和产业研究的咨询机构。知观智库，以“极致缜密思维为客户创造价值 ”为理念，以中国信通院产融数据为基础，从专业咨询角度系统洞察龙江产业现状，梳理符合当地经济发展的产业链体系，助力政府在经济振兴、招商引资、产业金融等方面进行科学决策，帮助大型企业集团在龙江产业落地的企业尽调和上下游营商环境调研等，为金融和类金融机构提供产业经济报告等第三方咨询服务。知观智库致力于为政府和企业提供区域产业研究、产业链分析报告、重点企业培育方案和产业落地辅导四大核心业务版块的咨询服务。自成立以来，已为黑龙江省多市委办局和区域政府提供第三方咨询服务，已完成《航天产业研究报告》 、《哈尔滨数字经济产业研究报告》、《五常产业振兴行动计划方案》 、《黑龙江生物医药产业研究报告》 、《黑龙江畜牧产业研究报告》、《哈尔滨工业倍增方案》等。'
      // }]
    } else if (this.$route.query.id == 4) {
      this.name = "文化创意";
      this.sList = [
        {
          t: "黑龙江省吾尚智鼎文化发展有限公司",
          phone: "联系电话：0451-81023000/18245108077",
          info:
            "公司整体运营涉及品牌营销、书籍出版、文创研发、视频制作、空间设计、活动推广、新媒体运营七个领域，为客户提供文化创意事务的一站式解决方案。 文化力推动新经济。 黑龙江省吾尚智鼎文化发展有限公司，成立于2007年，国家二级广告企业、黑龙江省一级广告公司、省级“守合同重信用”企业、系黑龙江知名文化创意机构之一，获得国家级省级各类荣誉奖项60余项，服务客户百余家。 目前企业拥有两大文化创意IP。 其一是平台：建设运营“互联网+摄影+文旅”综合性交互网络媒体“光影视界网。网站立足黑土，服务龙江，拥有“两微一站”宣传阵地，形成了今日头条、知乎、百家号、搜狐、网易、腾讯等13家网络平台组成的融媒体矩阵，发布影像、文旅内容10000余条，举办各类赛事活动200余次，已经成为宣传我省旅游资源、促进文旅融合发展的知名品牌。 其二是产品，创办龙江特色文旅文创商品品牌“龙江游礼”。目前已经开发了四个系列百余种文化创意品类，拥有一个文创产品微店，成功注册文创商标12枚，服务了10余家企事业单位高端定制文创产品开发项目，获得了20余项文创产品赛事类奖项，参加了10余次全国各类文化产业展会博览会，拥有了一定的品牌知名度和美誉度。",
        },
      ];
    } else if (this.$route.query.id == 5) {
      this.name = "财税咨询";
      this.sList = [
        {
          t: "深圳华商投资控股有限公司",
          phone: "联系电话：0451-81023000/18245108077",
          info:
            "华商资本是一家专注于成长型企业隐形冠军打造的平台型企业， 通过“产业孵化、产业并购、产业投资”三大板块，为成长型企业股权资产价值提升、股权资产变现等核心问题，提供一站式整体解决方案。 华商资本是一家专注于成长型企业隐形冠军打造的平台型企业， 通过“产业孵化、产业并购、产业投资”三大板块，为成长型企业股权资产价值提升、股权资产变现等核心问题，提供一站式整体解决方案。 华商以“普及资本教育，助推企业转型升级；打造共享资源体，引领产业整合并购；开发股权资产，实现行业隐形冠军资本化”为使命，通过企业顶层生态布局，彻底解决企业“人才、资源、资金”等核心问题，通过陪伴式孵化服务，打造资源共享与企业互助平台，专为行业隐形冠军提供资源配套服务，全方位提升企业股权资产价值。 华商总部位于深圳，在政治中心北京、金融中心上海、经济中心深圳等地设立15家运营中心，在各运营中心打造“产业+平台+圈子”三位一体的综合服务平台！在以董事长吕和昊先生领航的核心团队带领下，华商团队及所有合伙人秉承“融”的境界，“易”的智慧，“爱”的发心，为实现资本强企、金融强国而努力奋斗！",
        },
        {
          t: "哈尔滨三次方信息科技有限公司",
          phone: "联系电话：0451-81023000/18245108077",
          info: "主营业务：税务筹划，企业管理培训等。",
        },
        {
          t: "黑龙江省昆仑诚信税务师事务所有限公司",
          phone: "联系电话：0451-81023000/18245108077",
          info:
            "税务代理：涉税专业服务 ；一般税务咨询；专业税务顾问；税收策划；涉税鉴证；纳税情况审查；涉税账务清理；涉税纠纷代理；其他涉税服务",
        },
      ];
    } else if (this.$route.query.id == 6) {
      this.name = "法律顾问";
      this.sList = [
        {
          t: "黑龙江元辰律师事务所",
          phone: "联系电话：0451-81023000/18245108077",
          info:
            "建设工程房地产、公司人事劳动、银行金融业务、债权风险清收，专项法律服务、法律顾问服务。黑龙江元辰律师事务所是黑龙江省司法厅批准成立的综合性律师事务所，是黑龙江省第一家以建设工程、银行金融、劳动争议、债权风险清收、房地产行业为主要业务范围的律师事务所。该所集合了一批具有优良职业素质、完整知识结构、朝气蓬勃、锐意进取的中青年律师，本着“专业”和“敬业”的两业精神和“以质量求信誉，以信誉谋发展”的原则，运用科学的管理方式、现代化的服务手段、前瞻性的企业管理理念为广大当事人提供专业、高效、优质的法律服务，以捍卫当事人的合法权益。",
        },
      ];
    } else if (this.$route.query.id == 7) {
      this.name = "融资担保";
      this.sList = [
        {
          t: "哈尔滨开发区中小企业融资担保有限公司",
          phone: "联系电话：0451-81023000/18245108077",
          info:
            "按照融资性担保机构经营许可证核定的范围从事：融资担保业务：包括借款类担保业务、" +
            "发行债券担保业务和其他融资担保业务；非融资担保业务：包括投标担保、工程履约担保、" +
            "诉讼保全担保及其他非融资担保业务；与担保业务有关的咨询等服务业务；" +
            "其他合法合规业务（有效期至：二0二五年一月十四日）。从事科技风险投资、理财业务（不含金融证券投资）；物业管理。",
        },
      ];
    } else if (this.$route.query.id == 8) {
      this.name = "投资公司";
      this.sList = [
        {
          t: "哈尔滨云谷创业投资管理有限公司",
          phone: "联系电话：0451-81023000/18245108077",
          info:
            "企业投资咨询； 企业信息咨询；企业管理咨询；创业投资咨询；股权投资管理；" +
            "受托管理股权投资基金" +
            "（法律行政法规禁止的项目除外，法律行政法规限制的项目取得许可后方可经营）。",
        },
        {
          t: "哈尔滨云谷创业投资企业（有限合伙人)",
          phone: "联系电话：0451-81023000/18245108077",
          info: "以本合伙企业全部资金进行股权投资及相关投资管理、咨询服务。",
        },
      ];
    }
  },
};
</script>

<style lang="less" scoped>
.indetP {
  text-indent: 24px;
}
.spanBlod{
    font-weight: bold;
  }
.news-top {
  margin-top: 30px;
  padding: 15px 10px;
  background-color: @color-ff;
  border-radius: 5px;
  box-shadow: 0px 0px 10px #929292;
  position: relative;
  font-size: @font-xl;
  .news-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    ul {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 0;
      li {
        height: 40px;
        line-height: 40px;
        padding: 0 30px;
        border: 1px solid @color-blue;
        margin: 0 20px;
        border-radius: 20px;
        cursor: pointer;
      }
      .liactive {
        color: @color-ff;
        background-color: @color-blue;
      }
    }
  }
}
.news-top::before {
  content: "";
  position: absolute;
  left: -3px;
  top: 15px;
  width: 6px;
  height: 40px;
  background: @color-blue; /* 标准语法 */
}
.news-top::after {
  content: "";
  position: absolute;
  right: -3px;
  top: 15px;
  width: 6px;
  height: 40px;
  background: @color-blue; /* 标准语法 */
}
.news-con {
  padding: 20px;
  margin-top: 30px;
  margin-bottom: 30px;
  background-color: @color-ff;
  border-radius: 5px;
  box-shadow: 0px 0px 10px #929292;
  ul {
    li {
      display: flex;
      justify-content: space-between;
      padding: 10px 0;
      border-bottom: @border-base;
      span {
        display: block;
        height: 36px;
        line-height: 36px;
        font-size: 16px;
        color: @color-base;
      }
      .sp-title {
        width: 40%;
      }
      .sp-apply {
        width: 20%;
      }
      .sp-amount {
        width: 20%;
        color: @color-blue;
        font-weight: bold;
      }
      .sp-time {
        width: 10%;
        text-align: right;
      }
    }
    li:first-child {
      padding-top: 0;
    }
  }
}
.page-box {
  padding: 15px 20px;
  background-color: @color-ff;
  border-radius: 5px;
  box-shadow: 0px 0px 10px #929292;
  margin-bottom: 30px;
  position: relative;
  .ant-pagination {
    display: flex;
    justify-content: center;
  }
}
.page-box::before {
  content: "";
  position: absolute;
  left: -3px;
  top: 11px;
  width: 6px;
  height: 40px;
  background: @color-blue;
}
</style>
